@import "base.css";

.first-line {
    display: flex;
    justify-content: space-between;
}

.subheading-underlined {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid var(--default-color);
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--dark-gray);
    padding-top: 8px;
    margin-bottom: 12px;
}

.collection {
    border-bottom: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.collection-buttons {
    display: flex;
    align-items: center;
}

.price.oferta {
    position: relative;
}

.price.oferta::before {
    display: block;
    position: absolute;
    transform: rotate(-5deg);
    top: 50%;
    left: -5%;
    content: "";
    width: 110%;
    height: 1px;
    background-color: #d97640;
}

.price.oferta::after {
    position: absolute;
    transform: rotate(10deg);
    top: -20px;
    right: -50px;
    content: "30%";
    color: #d97640;
}

@media (max-width: 767px) {
    .first-line {
        margin-bottom: 50px;
    }

    .momentos {
        margin-bottom: 16px;
    }

    .subheading {
        margin-bottom: 41px;
    }

    .subheading-underlined {
        padding-bottom: 8px;
    }

    .author-desktop {
        display: none;
    }

    .author-mobile {
        margin-top: 8px;
        display: block;
        text-align: right;
    }

    .intro {
        margin-bottom: 40px;
    }

    .intro2 {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px 0;
    }

    .collection {
        padding: 32px 0 40px 0;
        margin-bottom: 24px;
    }

    .collection-subtitle {
        margin-bottom: 32px;
    }

    .collection-buttons {
        gap: 0 24px;
    }

    .god-nazad {
        margin-bottom: 24px;
    }

    .three {
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        gap: 40px 0;
    }

    .three-image {
        margin-bottom: 16px;
    }

    .three-title {
        margin-bottom: 8px;
    }

    .contacts {
        padding-top: 24px;
        border-top: 1px solid var(--default-color);
    }
}

@media (min-width: 768px) and (max-width: 1279px) {
    .first-line {
        margin-bottom: 26px;
    }

    .momentos {
        margin-bottom: 32px;
    }

    .subheading {
        margin-bottom: 29px;
    }

    .subheading-underlined {
        padding-bottom: 8px;
    }

    .author-desktop {
        display: block;
    }

    .author-mobile {
        display: none;
    }

    .intro {
        margin-bottom: 80px;
    }

    .intro2 {
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 0 20px;
        align-items: end;
    }

    .collection {
        padding: 32px 0 40px 0;
        margin-bottom: 20px;
    }

    .collection-subtitle {
        margin-bottom: 32px;
    }

    .collection-buttons {
        gap: 0 24px;
    }

    .god-nazad {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 0 20px;
        margin-bottom: 12px;
    }

    .god-nazad2 {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 0 20px;
    }

    .three {
        margin-bottom: 81px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0 20px;
    }

    .three-image {
        margin-bottom: 16px;
    }

    .three-title {
        margin-bottom: 8px;
    }

    .contacts {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 0 20px;
        padding-top: 24px;
        border-top: 1px solid var(--default-color);
    }
}

@media (min-width: 1280px) {
    .first-line {
        margin-bottom: 64px;
    }

    .momentos {
        margin-bottom: 32px;
    }

    .subheading {
        margin-bottom: 64px;
    }

    .subheading-underlined {
        padding-bottom: 24px;
        border-bottom: 4px solid var(--default-color);
    }

    .author-desktop {
        display: block;
    }

    .author-mobile {
        display: none;
    }

    .intro {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 0 20px;
        margin-bottom: 80px;
    }

    .intro2 {
        margin-bottom: 32px;
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 0 20px;
        align-items: end;
    }

    .collection {
        padding: 40px 0 80px 0;
        margin-bottom: 40px;
    }

    .collection-subtitle {
        margin-bottom: 48px;
    }

    .collection-buttons {
        gap: 0 40px;
    }

    .god-nazad {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 0 20px;
    }

    .god-nazad2 {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 0 20px;
    }

    .three {
        margin-bottom: 88px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0 20px;
    }

    .three-image {
        margin-bottom: 32px;
    }

    .three-title {
        margin-bottom: 22px;
    }

    .contacts {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 0 20px;
        padding-top: 32px;
        border-top: 1px solid var(--default-color);
    }
}
