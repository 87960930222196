@import "normalize.css";
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif&display=swap");

* {
    box-sizing: border-box;
}

img {
    width: 100%;
    height: auto;
}

body {
    font-family: "IBM Plex Serif", serif;
    --light-gray: rgba(0, 0, 0, 4%);
    --light-gray-hover: rgba(0, 0, 0, 8%);
    --gray: rgba(0, 0, 0, 12%);
    --dark-gray: rgba(0, 0, 0, 24%);
    --secondary-color: rgba(0, 0, 0, 60%);
    --default-color: rgba(0, 0, 0, 90%);
    --default-color-hover: rgba(0, 0, 0, 80%);
    --default-color-active: rgba(0, 0, 0, 70%);
    --disabled-color: rgba(0, 0, 0, 38%);
    --success-color: #238700;
    --error-color: #cd0000;
    min-width: 320px;
    max-width: 1280px;
    color: var(--default-color);
    margin: 0 auto;
    box-sizing: border-box;
}

html[lang="ru"] .es,
html[lang="es"] .ru {
    display: none;
}

select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
}

a,
select,
option {
    text-decoration: none;
    font-weight: inherit;
    color: inherit;
}

select,
section:focus,
section:focus-visible,
section:focus-within,
section:hover {
    border: none;
    background-color: unset;
    outline-color: unset;
    outline-style: unset;
    outline-width: unset;
    cursor: pointer;
}

/* For IE10 */
select::-ms-expand {
    display: none;
}

.header {
    position: relative;
    border-bottom: 2px solid var(--default-color);
    margin-bottom: 40px;
}

.gallery {
    display: flex;
    gap: 0 20px;
    margin-top: 80px;
}

.gallery-img-main {
    width: 66%;
}

.subgallery {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.language-select {
    border: unset;
    border-bottom: 1px solid var(--dark-gray);
    width: auto;
    text-align: center;
}

.language-select:hover {
    cursor: pointer;
    border-bottom: 1px solid var(--default-color);
}

.link {
    border-bottom: 1px solid var(--dark-gray);
    color: unset;
    cursor: unset;
    text-decoration: unset;
}

.link:hover {
    cursor: pointer;
    border-bottom: 1px solid var(--default-color);
}

.link.white {
    border-bottom-color: rgba(255, 255, 255, 0.24);
}

.link.white:hover {
    border-bottom-color: rgb(255, 255, 255);
}

button {
    border: unset;
    color: inherit;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
}

button:disabled,
button.primary:disabled,
button.white:disabled,
button.secondary:disabled,
button.primary:disabled:hover,
button.white:disabled:hover,
button.secondary:disabled:hover,
button:disabled:hover {
    opacity: 0.6;
    color: var(--disabled-color);
    background-color: var(--light-gray-hover);
    cursor: auto;
}

button .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

button:not(.loading) .loader {
    display: none;
}

button.loading span {
    visibility: hidden;
}

label:not(.radio):not(.checkbox),
.label:not(.radio):not(.checkbox) {
    display: block;
    color: var(--secondary-color);
    margin-bottom: var(--8-8-12);
}

div.secondary,
span.secondary,
a.secondary,
h1.secondary,
h2.secondary,
h3.secondary {
    color: var(--disabled-color);
}

button.primary {
    background-color: var(--default-color);
    color: white;
}

button.white {
    background-color: white;
    color: var(--default-color);
}

button.white:hover {
    opacity: 0.8;
}

button.white:active {
    opacity: 0.6;
}

button.primary:hover {
    background-color: var(--default-color-hover);
}

button.primary:active {
    background-color: var(--default-color-active);
}

button.secondary {
    background-color: var(--light-gray);
    color: var(--default-color);
}

button.secondary:hover {
    background-color: var(--light-gray-hover);
}

button.secondary:active {
    background-color: var(--gray);
}

input:not([type="checkbox"]):not([type="radio"]) {
    border-radius: 8px;
    border: 1px solid var(--gray);
    color: inherit;
    width: 100%;
}

input:not([type="checkbox"]):not([type="radio"]):focus {
    outline: none;
    border: 1px solid var(--default-color);
}

::placeholder {
    color: var(--disabled-color);
}

.radio-group {
    display: flex;
    flex-wrap: wrap;
}

.radio {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--gray);
    cursor: pointer;
}

.radio:hover {
    background-color: var(--light-gray);
}

input[type="radio"] {
    width: var(--s);
    height: var(--s);
    aspect-ratio: 1;
    border: 1px solid var(--default-color);
    padding: calc(var(--s) / 4 - 1px);
    border-radius: 50%;
    background: var(--_c, #00000000) content-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

input[type="radio"]:checked {
    --_c: var(--default-color);
}

input[type="checkbox"] {
    width: var(--s);
    height: var(--s);
    border: 1px solid var(--default-color);
    border-radius: 4px;
    background: var(--_c, #00000000) content-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox:hover input[type="checkbox"]:not(:checked) {
    background-color: var(--light-gray);
}

input[type="checkbox"]:checked {
    --_c: var(--default-color);
}

input[type="checkbox"]:checked::after {
    content: "✓";
    color: white;
}

.checkbox {
    display: flex;
    align-items: start;
    cursor: pointer;
}

.header .first-line {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.heading {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

@media (max-width: 767px) {
    body {
        font-size: 16px;
        line-height: 20px;
        padding: 8px 16px 40px 16px;
        --8-8-16: 8px;
        --16-16-24: 16px;
        --40-40-80: 40px;
        --16-20-32: 16px;
        --24-24-32: 24px;
        --8-8-12: 8px;
        --32-32-40: 32px;
        --32-32-48: 32px;
    }

    h1 {
        font-size: 40px;
        line-height: 48px;
        margin: unset;
        font-weight: unset;
        margin-block: unset;
        margin-inline: unset;
    }

    h2 {
        font-size: 40px;
        line-height: 48px;
        margin: unset;
        font-weight: unset;
        margin-block: unset;
        margin-inline: unset;
    }

    h3 {
        font-size: 24px;
        line-height: 32px;
        margin: unset;
        font-weight: unset;
        margin-block: unset;
        margin-inline: unset;
    }

    p,
    .p {
        margin: 0 0 8px 0;
        margin-inline: unset;
    }

    button {
        padding: 8px 24px 12px 24px;
        font-size: 20px;
        line-height: 26px;
    }

    input {
        padding: 8px 16px 12px 16px;
        font-size: 16px;
        line-height: 20px;
    }

    .link-button {
        font-size: 20px;
        line-height: 26px;
    }

    .radio-group {
        gap: 12px 8px;
    }

    .radio {
        padding: 8px 16px 12px 16px;
        gap: 0 8px;
    }

    input[type="radio"],
    input[type="checkbox"] {
        --s: 20px;
    }

    .checkbox {
        gap: 0 8px;
    }

    .header .first-line {
        padding-bottom: 110px;
    }

    .heading {
        width: 100%;
        bottom: 10px;
    }

    .gallery {
        flex-direction: column;
        gap: 20px 0;
    }

    .gallery-img-main {
        width: 100%;
    }

    .subgallery {
        gap: 20px 0;
    }
}

@media (min-width: 768px) and (max-width: 1279px) {
    body {
        font-size: 16px;
        line-height: 20px;
        padding: 8px 40px 40px 40px;
        --8-8-16: 8px;
        --16-16-24: 16px;
        --40-40-80: 40px;
        --16-20-32: 20px;
        --24-24-32: 24px;
        --8-8-12: 8px;
        --32-32-40: 32px;
        --32-32-48: 32px;
    }

    h1 {
        font-size: 64px;
        line-height: 84px;
        margin: unset;
        font-weight: unset;
        margin-block: unset;
        margin-inline: unset;
    }

    h2 {
        font-size: 40px;
        line-height: 54px;
        margin: unset;
        font-weight: unset;
        margin-block: unset;
        margin-inline: unset;
    }

    h3 {
        font-size: 24px;
        line-height: 32px;
        margin: unset;
        font-weight: unset;
        margin-block: unset;
        margin-inline: unset;
    }

    p,
    .p {
        margin: 0 0 8px 0;
        margin-inline: unset;
    }

    button {
        padding: 8px 24px 12px 24px;
        font-size: 20px;
        line-height: 26px;
    }

    input {
        padding: 8px 16px 12px 16px;
        font-size: 16px;
        line-height: 20px;
    }

    .link-button {
        font-size: 20px;
        line-height: 26px;
    }

    .radio-group {
        gap: 24px 8px;
    }

    .radio {
        padding: 16px 24px 20px 24px;
        gap: 0 12px;
    }

    input[type="radio"],
    input[type="checkbox"] {
        --s: 32px;
    }

    .checkbox {
        gap: 0 12px;
    }

    .header .first-line {
        padding-bottom: 27px;
    }

    .heading {
        width: 216px;
        top: 4px;
    }
}

@media (min-width: 1280px) {
    body {
        font-size: 24px;
        line-height: 32px;
        padding: 16px 40px 40px 40px;
        --8-8-16: 16px;
        --16-16-24: 24px;
        --40-40-80: 80px;
        --16-20-32: 32px;
        --24-24-32: 32px;
        --8-8-12: 12px;
        --32-32-40: 40px;
        --32-32-48: 48px;
    }

    h1 {
        font-size: 96px;
        line-height: 124px;
        margin: unset;
        font-weight: unset;
        margin-block: unset;
        margin-inline: unset;
    }

    h2 {
        font-size: 64px;
        line-height: 84px;
        margin: unset;
        font-weight: unset;
        margin-block: unset;
        margin-inline: unset;
    }

    h3 {
        font-size: 40px;
        line-height: 54px;
        margin: unset;
        font-weight: unset;
        margin-block: unset;
        margin-inline: unset;
    }

    p,
    .p {
        margin: 0 0 16px 0;
        margin-inline: unset;
    }

    button {
        padding: 16px 40px 20px 40px;
        font-size: 32px;
        line-height: 40px;
    }

    input {
        padding: 16px 24px 20px 24px;
        font-size: 24px;
        line-height: 32px;
    }

    .link-button {
        font-size: 32px;
        line-height: 40px;
    }

    .radio-group {
        gap: 24px 8px;
    }

    .radio {
        padding: 16px 24px 20px 24px;
        gap: 0 12px;
    }

    input[type="radio"],
    input[type="checkbox"] {
        --s: 32px;
    }

    .checkbox {
        gap: 0 12px;
    }

    .header {
        border-bottom: 4px solid var(--default-color);
    }

    .header .first-line {
        padding-bottom: 53px;
    }

    .heading {
        width: 386px;
        top: 8px;
    }
}
